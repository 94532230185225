import { GetRequest, PostRequest, successParam } from '@/api/base';

export interface GetScreenSaverList {
    total: number;
    rows: {
        UUID: string;
        Location: string;
        Status: string;
        MAC: string;
        Path: string;
        ImgUUID: string;
        Mode: number;
    }[];
    detail: GetScreenSaverList['rows'];
}
export interface GetScreenSaverDeviceList {
    total: number;
    rows: {
        UUID: string;
        Location: string;
    }[];
    detail: GetScreenSaverDeviceList['rows'];
}

export interface GetScreenSaverImgList {
    system: {
        UUID: string;
        Path: string;
    }[];
    project: {
        UUID: string;
        Path: string;
    }[];
}
class ScreenSaverApi {
    getScreenSaverList(params: object, callback: (res: GetScreenSaverList) => void) {
        GetRequest('v3/web/community/screenSaver/getScreenSaverList', params, callback);
    }

    getScreenSaverDeviceList(params: object, callback: (res: GetScreenSaverDeviceList) => void) {
        GetRequest('v3/web/community/screenSaver/getScreenSaverDeviceList', params, callback);
    }

    getScreenSaverImgList(params: object, callback: (res: GetScreenSaverImgList) => void) {
        GetRequest('v3/web/community/screenSaver/getScreenSaverImgList', params, callback);
    }

    delScreenSaverImg(params: object, callback: successParam) {
        PostRequest('v3/web/community/screenSaver/delScreenSaverImg', params, callback);
    }

    uploadScreenSaverImg(params: object, callback: successParam) {
        PostRequest('v3/web/community/screenSaver/uploadScreenSaverImg', params, callback);
    }

    addScreenSaver(params: object, callback: successParam) {
        PostRequest('v3/web/community/screenSaver/addScreenSaver', params, callback);
    }

    editScreenSaver(params: object, callback: successParam) {
        PostRequest('v3/web/community/screenSaver/editScreenSaver', params, callback);
    }
}

export default new ScreenSaverApi();